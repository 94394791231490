<template>
  <div class="Demo-DOH-PQR-CHECK mb-4">
    <div
      class="
        col-lg-6 col-md-12
        container-md
        justify-content-center
        mt-3
        border-doh
        px-0
      "
    >
      <LoadingOverlay v-if="isFetchingFilter" />
      <div v-if="!isPQRChecked">
        <h4 class="bg-doh p-2 font-adjust">
          Check your Abu Dhabi Medical License Eligibility by Entering Your
          Details Below
        </h4>
        <div class="px-3">
          <ValidationObserver ref="pqrCheck" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)" name="pqrCheck">
              <CRow class="mb-3">
                <label class="required col-lg-12 col-md-12">Profession</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <Select
                      name="candidate_type_id"
                      :value="pqr.candidate_type_id"
                      @input="handleChangeSelect"
                      :options="
                        options && options['candidateType']
                          ? options['candidateType']
                          : []
                      "
                      :taggable="false"
                      :multiple="false"
                      :clearable="false"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow class="row mb-3">
                <label class="col-lg-12 col-md-12 required"
                  >Highest Qualification Obtained In</label
                >
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <Select
                      name="main_qualification_country_id"
                      :value="pqr.main_qualification_country_id"
                      @input="handleChangeSelect"
                      :options="
                        options && options['locationInfo']
                          ? options['locationInfo']
                          : []
                      "
                      :taggable="false"
                      :multiple="false"
                      :clearable="false"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow class="mb-3">
                <label class="required col-lg-12 col-md-12"
                  >Qualification Name</label
                >
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <Select
                      name="qualification_id"
                      :value="pqr.qualification_id"
                      @input="handleChangeSelect"
                      :options="
                        options && options['qualification']
                          ? options['qualification']
                          : []
                      "
                      :taggable="false"
                      :multiple="false"
                      :clearable="false"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow class="mb-3">
                <label class="required col-lg-12 col-md-12"
                  >Month / Year Awarded</label
                >
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <DateInput
                      :onlyMonthAndYear="true"
                      name="qualified_date"
                      :value="pqr.qualified_date"
                      @change="handleDatePickerChange"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow
                name="registered_as"
                class="row mb-3"
                v-if="isNurse && isPGQualification"
              >
                <label class="col-lg-12 col-md-12 required"
                  >Are you Licensed/Registered as a Speciality Nurse or
                  (Advanced) Nurse Practitioner?</label
                >
                <CCol md="12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <RadioButton
                      name="speciality_nurse_or_nurse_practitioner"
                      :value="pqr['speciality_nurse_or_nurse_practitioner']"
                      :options="
                        options && options['boolean'] ? options['boolean'] : []
                      "
                      @change="handleChangeRadio"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </CCol>
              </CRow>
              <!-- <CRow class="mb-3">
                <label class="required col-lg-12 col-md-12">Issued By</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <Select
                      name="issued_by"
                      :value="pqr.issued_by"
                      @input="handleChangeSelect"
                      :options="
                        options && options['issued_by']
                          ? options['issued_by']
                          : []
                      "
                      :taggable="false"
                      :multiple="false"
                      :clearable="false"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow> -->
              <CRow>
                <CCol
                  lg="6"
                  v-if="
                    pqr.qualification_id &&
                    pqr.qualification_id.specialist_registration_reqd
                  "
                >
                  <CRow class="row mb-3">
                    <label class="required col-lg-12 col-md-12"
                      >Are you on a Specialist Register?</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider
                        rules="required"
                        v-slot="{ errors }"
                        ref="specialist_registration"
                      >
                        <RadioButton
                          name="specialist_registration"
                          :value="pqr.specialist_registration"
                          :options="
                            options && options['specialistRegister']
                              ? options['specialistRegister']
                              : []
                          "
                          @change="handleChangeRadio"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol lg="6" v-if="payload.specialist_registration">
                  <CRow class="row mb-3">
                    <label class="col-lg-12 col-md-12 required"
                      >Date Awarded</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <DateInput
                          :onlyMonthAndYear="true"
                          name="acquired_date"
                          :value="pqr.acquired_date"
                          @input="handleDatePickerChange"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
              <!-- <CRow class="row mb-3">
                <label
                  name="lbl_course_duration"
                  class="col-lg-12 col-md-12 required"
                >
                  Course Duration (yrs)
                  <strong>
                    Selected -
                    {{ pqr.course_duration ? pqr.course_duration : "0" }}
                  </strong>
                </label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <v-slider
                    name="course_duration"
                    :min="0"
                    :max="10"
                    :value="pqr.course_duration"
                    @change="handleSlider"
                  ></v-slider>
                </div>
              </CRow> -->
              <CRow name="speciality" class="row mb-3">
                <label class="required col-lg-12 col-md-12">Speciality</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <Select
                      name="speciality_id"
                      :value="pqr.speciality_id"
                      @input="handleChangeSelect"
                      :options="
                        options && options['speciality']
                          ? options['speciality']
                          : []
                      "
                      :taggable="false"
                      :multiple="false"
                      :clearable="false"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow
                name="sub_speciality"
                class="row mb-3"
                v-if="options['subSpeciality'].length"
              >
                <label class="col-lg-12 col-md-12">{{
                  isNurse ? `Area of Expertise` : `Sub Speciality`
                }}</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <Select
                    name="sub_speciality_id"
                    :value="pqr.sub_speciality_id"
                    @input="handleChangeSelect"
                    :options="
                      options && options['subSpeciality']
                        ? options['subSpeciality']
                        : []
                    "
                    :taggable="false"
                    :multiple="false"
                    :clearable="true"
                  />
                </div>
              </CRow>
              <div class="mb-2 d-flex justify-content-end">
                <CButton color="primary" class="d-flex m-2 px-4" type="submit">
                  Check
                </CButton>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
      <div v-else class="p-3">
        <CCard>
          <CCardBody>
            <h5>Eligibility Matched</h5>
            <CRow class="mb-2">
              <CCol col="5" class="pr-0">Eligibility Country</CCol>
              <CCol col="7" class="pr-0">{{
                getStandAlonePQR.pqr_country || "--"
              }}</CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol col="5" class="pr-0">Authority</CCol>
              <CCol col="7" class="pr-0">{{
                getStandAlonePQR.pqr_issue_authority || "--"
              }}</CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol col="5" class="pr-0">Eligibility Level</CCol>
              <CCol col="7" class="pr-0">{{
                getStandAlonePQR.pqr_detail_description || "Not Eligible"
              }}</CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol col="5" class="pr-0">Comments</CCol>
              <CCol col="7" class="pr-0">{{ pqrComments || "--" }}</CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol col="5" class="pr-0">Date Created</CCol>
              <CCol col="7" class="pr-0">{{
                dateFormat(getStandAlonePQR.created_date)
              }}</CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <div class="mb-2 px-5">
          <CButton color="primary" class="w-100" @click="resetPQR">
            Check Another Qualification
          </CButton>
        </div>
        <div class="px-5">
          <CButton color="primary" class="w-100" @click="navToDOHJobBoard">
            Register Now
          </CButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const debounce = require("lodash.debounce");
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select.vue";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import CheckBox from "@/components/reusable/Fields/CheckBox";
import DateInput from "@/components/reusable/Fields/DateInput";
import { mapActions, mapGetters } from "vuex";
import m from "moment";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
import _ from "lodash";
export default {
  components: {
    Select,
    RadioButton,
    CheckBox,
    DateInput,
  },

  data() {
    return {
      pqr: {},
      payload: {},
    };
  },
  computed: {
    ...mapGetters([
      "isFetchingFilter",
      "candidateType",
      "locationCountryInfo",
      "qualificationByCountryAndCandidateType",
      "specialityFilterByType",
      "subSpecialityFilterBySpeciality",
      "qualificationIssueAuthorityByCountry",
      "getStandAlonePQR",
    ]),
    options() {
      return {
        candidateType: this.candidateType || [],
        locationInfo: this.locationCountryInfo || [],
        qualification: this.customQualificationOptions,
        speciality:
          this.specialityFilterByType({
            candidateType: this.payload.candidate_type_id,
          }) || [],
        subSpeciality:
          this.subSpecialityFilterBySpeciality({
            speciality: this.payload.speciality_id,
          }) || [],
        issued_by:
          this.qualificationIssueAuthorityByCountry({
            country_id: this.payload.main_qualification_country_id,
            qualification_id: this.payload.qualification_id,
          }) || [],
        specialistRegister: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
      };
    },
    isNurse() {
      return this.pqr?.candidate_type_id?.code
        ? this.pqr.candidate_type_id.code === 2
        : false;
    },
    customQualificationOptions() {
      const qualifications = this.qualificationByCountryAndCandidateType;
      return qualifications.length
        ? qualifications.filter((e) => e.code !== 241)
        : [];
    },
    isPQRChecked() {
      return (
        isObject(this.getStandAlonePQR) &&
        !isEmptyObjectCheck(this.getStandAlonePQR)
      );
    },
    pqrComments() {
      return this.getStandAlonePQR?.pqr_detail_comments
        ? this.getStandAlonePQR?.pqr_detail_comments
        : this.isNurse && !this.getStandAlonePQR.pqr_detail_description
        ? "Does not meet the qualification/experience required for licensure as Assistant Nurse/Midwife"
        : "--";
    },
    isPGQualification() {
      const sortLevel =
        this.customQualificationOptions?.find((v) =>
          v?.qualification_classification_name?.includes("First Degree")
        )?.qualification_classification_sort_level || null;
      return (
        (sortLevel &&
          this.pqr?.qualification_id?.qualification_classification_sort_level >=
            sortLevel) ||
        false
      );
    },
  },
  watch: {
    "pqr.specialist_registration"() {
      this.pqr.acquired_date = null;
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "initDemoDOHPQRCheck",
      "fetchQualificationByCountryAndCandidateType",
      "fetchIssueAuthorityByQualification",
      "checkStandAlonePQR",
    ]),
    navToDOHJobBoard() {
      this.$router.push({ path: `/dohae/register/2` });
    },
    handleChangeSelect(name, value) {
      Vue.set(this.pqr, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
      if (name === "qualification_id") {
        this.handleChangeRadio("specialist_registration", null);
        this.$nextTick(() => {
          this.$refs.specialist_registration?.errors.clear;
          this.$refs.specialist_registration?.reset();
        });
        this.handleChangeRadio("speciality_nurse_or_nurse_practitioner", false);
      }
      if (
        name === "candidate_type_id" ||
        name === "main_qualification_country_id"
      ) {
        this.fetchQualificationByCountryAndCandidateType({
          country_id: this.payload.main_qualification_country_id,
          candidate_type_id: this.payload.candidate_type_id,
        });
      }
    },
    handleDatePickerChange(name, value) {
      Vue.set(this.pqr, name, m(value).format("YYYY-MM-DD"));
      const yearMonth = value.split("-");
      if (name === "qualified_date") {
        this.payload = {
          ...this.payload,
          year_awarded: yearMonth[0],
          month_awarded: yearMonth[1],
        };
      } else if (name === "acquired_date") {
        this.payload = {
          ...this.payload,
          specialist_registration_year: yearMonth[0],
          specialist_registration_month: yearMonth[1],
        };
      }
    },
    handleSlider: debounce(function (value) {
      Vue.set(this.pqr, "course_duration", value);
      Vue.set(this.payload, "course_duration", value);
    }, 500),
    handleChangeRadio(name, value) {
      let val = null;
      if (value === null) {
        val = null;
      } else if (
        value.id === false ||
        value.code === false ||
        value === false
      ) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }
      Vue.set(this.pqr, name, val);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
    },
    async onSubmit() {
      const isValid = await this.$refs.pqrCheck.validate();
      const errors = Object.values(this.$refs.pqrCheck.errors).flat();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: errors[0] || "please fill mandatory fields!",
        });
        return;
      }
      let finalPayload = {
        candidate_type_id: this.payload?.candidate_type_id,
        country_id: this.payload?.main_qualification_country_id,
        qualification_id: this.payload?.qualification_id,
        month_awarded: this.payload?.month_awarded,
        year_awarded: this.payload?.year_awarded,
        speciality_id: this.payload?.speciality_id,
        specialist_registration: this.payload?.specialist_registration || false,
      };
      if (this.payload?.specialist_registration) {
        finalPayload = {
          ...finalPayload,
          specialist_registration_month:
            this.payload?.specialist_registration_month,
          specialist_registration_year:
            this.payload?.specialist_registration_year,
        };
      }
      if (this.isNurse) {
        finalPayload = {
          ...finalPayload,
          speciality_nurse_or_nurse_practitioner:
            this.payload?.speciality_nurse_or_nurse_practitioner,
        };
      }
      this.checkStandAlonePQR(finalPayload);
    },
    resetPQR() {
      this.$store.commit("RESET_STAND_ALONE_PQR");
      this.pqr = {};
      this.payload = {};
    },
    dateFormat(data) {
      if (data) return m(data).format("DD-MMM-YYYY");
      return "--";
    },
  },
  mounted() {
    this.initDemoDOHPQRCheck();
  },
};
</script>
<style lang="scss" scoped>
.font-adjust {
  @media (min-width: 420px) {
    font-size: 16px;
  }
  @media (max-width: 420px) {
    font-size: 14px;
  }
}
.bg-doh {
  background: #085d66;
}
.border-doh {
  border: 2px solid #085d66;
}
.card {
  background-color: #edf7f8;
}
</style>