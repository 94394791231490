<template>
  <div class="Demo-DOH">
    <header
      class="
        bg-clr
        sticky-top
        flex-d
        c-header
        c-header-light
        c-header-fixed
        c-header-with-subheader
        c-fixed-height
      "
    >
      <div style="margin-top: auto; margin-bottom: auto">
        <div class="logo pr-1 mobile-pl-1 mx-2">
          <div>
            <a
              href="https://www.doh.gov.ae/covid-19/Health-Workforce-Management-System"
              target="_blank"
              class="p-1 d-inline-block"
              ><img src="/img/white-logo-doh.svg" class="mw-100 h-auto"
            /></a>
          </div>
        </div>
      </div>
      <span class="d-flex flex-column logo-title linespcad title">
        <span>
          <small
            >Kawader - Smart platform for Abu Dhabi's Healthcare
            Workforce</small
          >
        </span>
        <span>
          <small>كــــوادر- منصة ذكية لإدارة الكوادر الصحية في أبوظبي</small>
        </span>
      </span>
      <div class="d-flex align-items-center right-action">
        <router-link
          to="/public/job-board/2"
          class="c-header-nav-link text-white hover-decoration-none mr-2"
          >Back to Job Board</router-link
        >
      </div>
    </header>
    <div
      style="margin-bottom: 3rem; padding-top: 2.5rem; padding-bottom: 5rem"
      class="container-fluid"
    >
      <PQRCheck />
    </div>
    <footer
      class="
        page-footer
        f-bg
        font-small
        white
        text-white
        c-footer c-footer-fixed
      "
    >
      <div>
        <div class="ml-1 copy-right">
          © 2011 – 2020 OHR Ltd or its affiliates
        </div>
      </div>
      <div class="ml-auto d-flex flex-column">
        <span class="mr-1"
          ><span class="fw-b"
            ><div class="logo-adjustment">powered by</div>
            TALENT</span
          ><span class="fw-5">FIND™</span></span
        >
      </div>
    </footer>
  </div>
</template>

<script>
import PQRCheck from "@/pages/DemoDOH/PQRCheck";
export default {
  name: "DemoDOHPQRCheck",
  components: {
    PQRCheck,
  },
  data: () => ({
    isMobile: false,
  }),
  mounted() {
    let isMobile = window.matchMedia(
      "only screen and (max-width: 420px)"
    ).matches;
    this.isMobile = isMobile;
  },
};
</script>

<style lang="scss" scoped>
.Demo-DOH {
  .bg-clr {
    color: #ffffff !important;
  }
  .logo {
    // margin-right: 10px;
    width: 9rem;
  }
  .c-header.c-header-fixed {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .c-header[class*="bg-"] {
    border-color: rgba(0, 0, 21, 0.1);
  }
  .bg-clr {
    background: #0a5e67;
  }
  .container-fluid {
    padding-right: 30px;
    padding-left: 30px;
  }
  .hover-decoration-none:hover {
    text-decoration: none;
  }
  @media (max-width: 480px) {
    .logo {
      width: 8rem !important;
    }
    .logo-title small {
      font-size: 10px !important;
    }
    .license-eligiblity {
      font-size: 12px !important;
      padding: 6px !important;
    }
    .right-action {
      padding-right: 4px !important;
      font-size: 11px !important;
    }
  }
}
</style>
